import React from "react";
import css from "./styles.module.css";
import { Button } from "../";
import { Link } from "react-router-dom";
import { Logo } from "../icons";
import { useAppContext } from "../../hooks";

function Footer() {
  const { setIsShowDemo } = useAppContext();

  const onShowDemo = () => {
    setIsShowDemo(true);
  };

  const onScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className={css.container}>
      <hr className={css.divider} />
      <div className={css.box}>
        <Link onClick={onScrollToTop} to="/" className={css.bigName}>
          <Logo />
          <span style={{ marginLeft: "5.5px" }}>
            AUDIENCE <br /> HUB
          </span>
        </Link>
        <nav className={css.nav}>
          <Link onClick={onScrollToTop} to="/market" className={css.smallUrl}>
            Маркет аудиторий
          </Link>
          <Link onClick={onScrollToTop} to="/contacts" className={css.smallUrl}>
            Контакты
          </Link>
          <Button onClick={onShowDemo} variant="black">
            Заявка на демо
          </Button>
        </nav>
      </div>
      <div className={css.nameRow}>
        <div className={css.name}>© AudienceHub, 2025</div>
        <div className={css.docRow}>
          <a
            className={css.doc}
            download="Политика_конфиденциальности_AudienceHub.pdf"
            href="./privacy.pdf"
            target="_blank"
          >
            Политика конфиденциальности
          </a>
          <a
            className={css.doc}
            download="Пользовательское_соглашение_AudienceHub.pdf"
            href="./userAgreement.pdf"
            target="_blank"
          >
            Пользовательское соглашение
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
