import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import styles from "./styles.module.css";
import classNames from "classnames";
import { ArrowDown } from "./ArrowDown";

const Dropdown = ({ label, onChoose, classes, options }) => (
  <label className={classNames(styles.label, classes)}>
    {label}
    <Select.Root onValueChange={onChoose} defaultValue="">
      <Select.Trigger className={styles.Trigger}>
        <Select.Value placeholder="Все" />
        <ArrowDown />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={styles.Content}>
          <Select.Viewport className={styles.Viewport}>
            {options.map((option) => (
              <SelectItem value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  </label>
);

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(styles.Item, className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
      </Select.Item>
    );
  },
);

export default Dropdown;
