import React, { useState } from "react";
import css from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { putResetPassword } from "../../api";
import useAppHook from "../../hooks/useAppContext";

const ResetPassword = () => {
  const [firstPass, setFirstPass] = useState("");
  const [secondPass, setSecondPass] = useState("");
  const [formError, setFormError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { setUserData } = useAppHook();

  const onConfirm = async () => {
    if (firstPass && secondPass) {
      if (firstPass === secondPass) {
        const token = new URLSearchParams(location.search).get("token");

        await putResetPassword({
          token: token || "",
          password: firstPass,
          password_confirmation: secondPass,
        })
          .then((res) => {
            const userData = res.data;
            const token = res.headers["x-session-token"];

            localStorage.setItem("token", token);
            localStorage.setItem("userData", JSON.stringify(userData));
            setUserData(userData);
            navigate("/");
          })
          .catch((e) => setFormError(e.response.data.message));
      } else {
        setFormError("Введенные пароли не совпадают");
      }
    }
  };

  const onInputChange = (type: string, value: string) => {
    setFormError("");
    if (type === "first") {
      setFirstPass(value);
    } else {
      setSecondPass(value);
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.form}>
        <div className={css.title}>Введите новый пароль</div>

        <div className={css.fields}>
          <Input
            styles={css.input}
            id="firstPass"
            label="Введите пароль"
            value={firstPass}
            type="password"
            onChange={(value) => onInputChange("first", value)}
          />
          <Input
            styles={css.input}
            id="secondPass"
            label="Введите пароль повторно"
            value={secondPass}
            type="password"
            onChange={(value) => onInputChange("second", value)}
          />
        </div>
        {formError && <div className={css.formError}>{formError}</div>}
        <Button onClick={onConfirm}>Отправить</Button>
      </div>
    </div>
  );
};

export default ResetPassword;
