import { TMarketItem, TMarketItemWithPrice } from "../../types";
import css from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { useAppContext } from "../../hooks";
import { useEffect, useMemo, useState } from "react";
import { postFreeOrders } from "../../api";

import toast, { Toaster } from "react-hot-toast";
import Notify from "../Notify/Notify";

type Props =
  | (TMarketItem & { layout?: "book " })
  | (TMarketItemWithPrice & {
      layout?: "album";
      setAuthOpen: (open: boolean) => void;
    });

const LinkList = ({
  links,
  layout,
}: {
  links: { id: number; link: string; title: string }[];
  layout: string;
}) => {
  const [isFullShow, setIsFullShow] = useState(false);

  const actualLinks = useMemo(() => {
    if (links.length > 3 && layout === "album") {
      if (isFullShow) {
        return links;
      } else {
        return links.filter((_el, i) => i <= 2);
      }
    } else return links;
  }, [isFullShow, links, layout]);

  const isRenderCollapse = links.length > 3 && layout === "album";
  const onFullShow = () => {
    setIsFullShow(!isFullShow);
  };

  return (
    <div>
      {actualLinks.map((el, i) => (
        <div style={{ marginTop: "14px" }}>
          <Link className={css.articleUrl} to={el.link}>
            {/* {i === links.length - 1 ? el.title : el.title + ", "} */}
            {el.title}
          </Link>
        </div>
      ))}
      {isRenderCollapse && (
        <div onClick={onFullShow} className={css.collapse}>
          {isFullShow ? "Свернуть" : "Развернуть"}
        </div>
      )}
    </div>
  );
};

function MarketItem(props: Props) {
  const { layout = "book", title, tags, articleUrl, userCount } = props;

  const navigate = useNavigate();
  const { setOrderData, userData } = useAppContext();
  const [buyButtonClicked, setBuyButtonClicked] = useState(false);

  useEffect(() => {
    if (buyButtonClicked && userData?.id && userData?.email) {
      navigate("/order");
      setOrderData(props as TMarketItemWithPrice);
      setBuyButtonClicked(false);
    }
  }, [
    userData?.id,
    userData?.email,
    navigate,
    props,
    setOrderData,
    buyButtonClicked,
  ]);

  const onBuy = () => {
    if ("setAuthOpen" in props) {
      props.setAuthOpen(true);
      setBuyButtonClicked(true);
    }
  };

  const onTryAddTestSegment = async () => {
    if (userData) {
      try {
        await postFreeOrders({ segment_id: props.id });
        toast.custom(
          <Notify
            title="Сегмент добавлен!"
            text="Вы успешно добавили пробный сегмент, вы можете увидеть его в «История  заказов»"
          />
        );
      } catch (e) {}
    } else {
      if ("setAuthOpen" in props) {
        window.scrollTo({
          top: 0,
        });
        props.setAuthOpen(true);
      }
    }
  };

  if (layout === "book") {
    return (
      <div className={css.example}>
        <div className={css.tags}>
          {tags.map((tag) => (
            <p className={css.tag}>{tag}</p>
          ))}
        </div>
        <div className={css.exampleBlock}>
          <div>
            <h3 className={css.exampleTitle}>{title}</h3>
            <LinkList links={articleUrl} layout={layout} />
            <div className={css.counters}>
              <div className={css.counter}>
                <p className={css.counterText}>
                  Актуальное число пользователей
                </p>
                <p className={css.counterValue}>{userCount}</p>
              </div>
            </div>
          </div>

          <Button
            onClick={onTryAddTestSegment}
            variant="black"
            styles={css.tryButton}
          >
            Попробовать аудиторию
          </Button>
        </div>
        <Toaster
          toastOptions={{
            style: {
              backgroundColor: 'red'
            }
          }} 
          position="top-right"
        />
      </div>
    );
  }

  const price = "price" in props ? props.price : "0";

  return (
    <div className={css.exampleAlbum}>
      <div className={css.tags}>
        {tags.map((tag) => (
          <p className={css.tag}>{tag}</p>
        ))}
      </div>
      <div className={css.exampleBlockAlbum}>
        <div className={css.titleBlockAlbum}>
          <h3 className={css.exampleTitle}>{title}</h3>
          <LinkList links={articleUrl} layout={layout} />
        </div>

        <div className={css.endPart}>
          <div className={css.counters}>
            <hr className={css.separator} />
            <div className={css.counterAlbum}>
              <p className={css.counterText}>Актуальное число пользователей</p>
              <p className={css.counterValue}>{userCount}</p>
            </div>
          </div>

          <div className={css.buyBlock}>
            <p className={css.price}>{price} руб</p>
            <Button variant="blue" styles={css.buyButton} onClick={onBuy}>
              Купить
            </Button>
          </div>
        </div>
      </div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "red",
            },
          },
        }}
        position="top-right"
      />
    </div>
  );
}

export default MarketItem;
