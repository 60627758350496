import { useState, useEffect } from "react";
import { putProfile } from "../../api";
import { Button, Input } from "../../components";
import useAppHook from "../../hooks/useAppContext";
import css from "./styles.module.css";

function AccountSettings() {
  const { userData, setUserData } = useAppHook();
  const [privateData, setPrivateData] = useState({
    last_name: userData?.last_name ?? "",
    first_name: userData?.first_name ?? "",
    company_name: userData?.company_name ?? "",
  });

  const [loginData, setLoginData] = useState({
    email: userData?.email ?? "",
    password: "",
    passwordCheck: "",
  });
  const [policyAccepted, setPolicyAccepted] = useState(false);

  useEffect(() => {
    setPrivateData({
      last_name: userData?.last_name ?? "",
      first_name: userData?.first_name ?? "",
      company_name: userData?.company_name ?? "",
    });
    setLoginData((loginData) => ({
      ...loginData,
      email: userData?.email ?? "",
    }));
  }, [userData]);

  const onSave = async () => {
    if (policyAccepted && loginData.password === loginData.passwordCheck) {
      const newProfile: Record<string, string> = {};

      Object.entries(privateData).forEach(([key, value]) => {
        if (value) {
          newProfile[key] = value;
        }
      });

      //if (loginData.email) {
      // newProfile.email = loginData.email;
      //}

      if (loginData.password) {
        newProfile.password = loginData.password;
      }

      putProfile(newProfile).then((response) => {
        setUserData(response.data.data);
      });
    }
  };

  return (
    <div className={css.container}>
      <h2 className={css.title}>Настройки аккаунта</h2>
      <div className={css.settings}>
        <div className={css.forms}>
          <div className={css.formBox}>
            <h3 className={css.formTitle}>ЛИЧНЫЕ ДАННЫЕ</h3>
            <div className={css.inputs}>
              <Input
                id="lastName"
                label="ФАМИЛИЯ"
                value={privateData.last_name}
                onChange={(value) =>
                  setPrivateData((data) => ({ ...data, last_name: value }))
                }
                styles={css.input}
              />
              <Input
                id="name"
                label="ИМЯ"
                value={privateData.first_name}
                onChange={(value) =>
                  setPrivateData((data) => ({ ...data, first_name: value }))
                }
                styles={css.input}
              />
              <Input
                id="companyName"
                label="КОМПАНИЯ"
                value={privateData.company_name}
                onChange={(value) =>
                  setPrivateData((data) => ({ ...data, company_name: value }))
                }
                styles={css.input}
              />
            </div>
          </div>
          <hr className={css.divider} />
          <div className={css.formBox}>
            <h3 className={css.formTitle}>ДАННЫЕ ДЛЯ ВХОДА</h3>
            <div className={css.inputs}>
              <Input
                id="email"
                label="ПОЧТА"
                value={loginData.email}
                onChange={(value) =>
                  setLoginData((data) => ({ ...data, email: value }))
                }
                styles={css.input}
              />
              <Input
                id="password"
                label="НОВЫЙ ПАРОЛЬ"
                value={loginData.password}
                onChange={(value) =>
                  setLoginData((data) => ({ ...data, password: value }))
                }
                styles={css.input}
              />
              <Input
                id="passwordCheck"
                label="ПОВТОРИТЕ ПАРОЛЬ"
                value={loginData.passwordCheck}
                onChange={(value) =>
                  setLoginData((data) => ({ ...data, passwordCheck: value }))
                }
                styles={css.input}
              />
            </div>
          </div>
        </div>
        <div className={css.policyBox}>
          <input
            type="checkbox"
            className={css.checkbox}
            checked={policyAccepted}
            onChange={() => setPolicyAccepted((checked) => !checked)}
          />
          <div className={css.textBox}>
            <p className={css.policyText}>
              Даю согласие на сбор, хранение и обработку информации согласно
              пользовательскому соглашению и политики конфиденциальности
            </p>
            <div className={css.policyLinks}>
              <p className={css.policyLink}>Политика конфиденциальности</p>
              <p className={css.policyLink}>Пользовательское соглашение</p>
            </div>
          </div>
        </div>
        <Button variant="blue" styles={css.saveButton} onClick={onSave}>
          Сохранить
        </Button>
      </div>
    </div>
  );
}

export default AccountSettings;
