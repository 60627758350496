import React from 'react'
import css from './styles.module.css'

const Notify = ({title, text}: {title: string, text: string}) => {
    return (
        <div className={css.container}>
            <div className={css.title}>{title}</div>
            <div className={css.text}>{text}</div>
        </div>
    )
}

export default Notify;