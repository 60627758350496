import React from "react";
import css from "./styles.module.css";
import Button from "../Button/Button";
import { getVerifyEmail } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onConfirm = async () => {
    const token = new URLSearchParams(location.search).get("token");
    await getVerifyEmail(token || "").then((res) => {
      navigate("/");
    });
  };

  return (
    <div className={css.wrapper}>
      <div className={css.form}>
        <div className={css.title}>Подтверждение почты</div>
        <div className={css.description}>
          Нажимая кнопку "Подтвердить" вы подтверждаете факт владения данной
          почтой и ваш аккаунт будет верефицирован
        </div>
        <Button onClick={onConfirm}>Подтвердить</Button>
      </div>
    </div>
  );
};

export default ConfirmPassword;
