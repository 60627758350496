import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowBack,
  ArrowForward,
  Button,
  Dropdown,
  MarketItem,
  Auth,
} from "../../components";
import { FaqSection, LeadSection } from "../Main";
import css from "./styles.module.css";
import classNames from "classnames";
import { useCategories, useSegments } from "../../api";
import { IconFilter } from "../../components/icons";

const timeOptions = [{ label: "> 1 минуты", value: "1" }];

const userCountOptions = [
  {label: "Все", value: 'default'},
  { label: "> 1000 человек", value: "1000" },
  { label: "> 3000 человек", value: "3000" },
  { label: "> 5000 человек", value: "5000" },
];

const Market = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [authOpen, setAuthOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    category: "",
    time_spent: "",
    user_count: "",
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { data: categories } = useCategories();

  const categoriesNames = useMemo(
    () => categories?.data.data.map((category) => category.name) ?? [],
    [categories]
  );

  const { isLoading, data, isPreviousData, refetch } = useSegments(
    page,
    10,
    filters
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    if (authOpen) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [authOpen]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const availablePages = useMemo(() => {
    const maxPages = data?.data.meta.page.total_pages ?? 0;

    if (page === 1) {
      return Array(Math.min(maxPages, 3))
        .fill(0)
        .map((_, i) => i + 1);
    }

    if (page === maxPages) {
      return Array(3)
        .fill(0)
        .map((_, i) => maxPages - 2 + i)
        .filter((el) => !!el);
    }

    return Array(3)
      .fill(0)
      .map((_, i) => page - 1 + i)
      .filter((el) => !!el);
  }, [data, page]);

  const categoriesNamesOptions = useMemo(() => {
    return [
      {label: 'Все', value: 'default'},
      ...categoriesNames.map((el) => ({label: el, value: el}))
    ]
  }, [categoriesNames])

  const onForwardButtonClick = () =>
    setPage((page) => {
      if (page !== data?.data.meta.page.total_pages) {
        scrollToTop();
      }

      return !isPreviousData && data?.data.meta.page.next_page
        ? page + 1
        : page;
    });

  const onBackButtonClick = () => {
    setPage((page) => {
      if (page !== 1) {
        scrollToTop();
      }

      return Math.max(page - 1, 1);
    });
  };

  const onPageNumberClick = (num: number) => {
    if (num !== page) {
      setPage(num);
      scrollToTop();
    }
  };

  const onSelectOption = (id: string) => (value: string) => {
    const actualValue = value === 'default' ? '' : value
    setFilters((filters) => ({ ...filters, [id]: actualValue }));
  };

  const dropDownAdaptive = () => {
    if (width >= 580) {
      return (
        <>
          <Dropdown
            classes={css.select}
            options={categoriesNamesOptions}
            label="Тематика"
            onChoose={onSelectOption("category")}
          />
          {/* <Dropdown
            classes={css.select}
            options={timeOptions}
            label="Время на странице"
            onChoose={onSelectOption("time_spent")}
          /> */}
          <Dropdown
            classes={css.select}
            options={userCountOptions}
            label="Число пользователей"
            onChoose={onSelectOption("user_count")}
          />
        </>
      );
    } else {
      return (
        <div
          className={classNames(
            css.mobileFilters,
            !isFiltersOpen && css.hideMobileFilters
          )}
        >
          <Dropdown
            classes={css.select}
            options={categoriesNames.map((name) => ({
              label: name,
              value: name,
            }))}
            label="Тематика"
            onChoose={onSelectOption("category")}
          />
          <Dropdown
            classes={css.select}
            options={timeOptions}
            label="Время на странице"
            onChoose={onSelectOption("time_spent")}
          />
          <Dropdown
            classes={css.select}
            options={userCountOptions}
            label="Число пользователей"
            onChoose={onSelectOption("user_count")}
          />
        </div>
      );
    }
  };

  const onCLickFiltersDropDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsFiltersOpen(!isFiltersOpen);
  };

  return (
    <>
      <section className={css.container}>
        <h2 className={css.title}>Маркет аудиторий</h2>
        <form className={css.filter}>
          {width < 580 && (
            <Button
              styles={css.filterButton}
              variant="white"
              onClick={(e: any) => onCLickFiltersDropDown(e)}
            >
              Фильтры
              <IconFilter />
            </Button>
          )}
          {dropDownAdaptive()}
        </form>
        {isLoading ? null : (
          <>
            <div className={css.results}>
              {data?.data.data.map((item) => (
                <MarketItem
                  key={item.id}
                  id={String(item.id)}
                  layout="album"
                  tags={item.tags}
                  price={String(item.price)}
                  title={item.name}
                  userTime={item.time_spent}
                  userCount={String(item.users_count)}
                  articleUrl={item.article_links}
                  leadingUrl={`/${item.id}`}
                  setAuthOpen={setAuthOpen}
                />
              ))}
            </div>

            <div className={css.pagination}>
              <Button
                variant="black"
                styles={classNames(css.backButton, css.paginationButton)}
                onClick={onBackButtonClick}
              >
                <ArrowBack className={css.arrowIcon} />
              </Button>
              {availablePages?.map((num) => (
                <Button
                  variant="black"
                  onClick={() => onPageNumberClick(num)}
                  styles={
                    num === page
                      ? classNames(css.paginationPage, css.selected)
                      : css.paginationPage
                  }
                >
                  {num}
                </Button>
              ))}

              <Button
                variant="black"
                styles={css.paginationButton}
                onClick={onForwardButtonClick}
              >
                <ArrowForward className={css.arrowIcon} />
              </Button>
            </div>
          </>
        )}
      </section>
      <FaqSection />
      <LeadSection />
      {authOpen && <Auth setAuthOpen={setAuthOpen} />}
    </>
  );
};

export default Market;
