import axios from "axios";
import {
  CategoryResponse,
  CreateOrder,
  MarketFilter,
  OrderResponse,
  Profile,
  SegmentResponse,
  UserProfile,
} from "./types";

const api = axios.create({
  baseURL: "https://api.audiencehub.ru/api",
});

export const getSegments = async (
  page: number,
  perPage: number,
  filter?: MarketFilter
) => {
  const url = "/v1/segments";

  if (!filter) {
    const response = await api.get<SegmentResponse>(url, {
      params: { page, per_page: perPage },
    });

    return response;
  }

  const response = await api.get<SegmentResponse>(url, {
    params: {
      page,
      per_page: perPage,
      "query[category_name_eq]": filter.category,
      "query[time_spent_gt]": filter.time_spent,
      "query[users_count_gt]": filter.user_count,
    },
  });

  return response;
};

export const getOrderHistory = async (page: number, perPage: number) => {
  const token = localStorage.getItem("token") ?? "";
  const url = "/v1/orders";

  const response = await api.get<OrderResponse>(url, {
    params: { page, per_page: perPage },
    headers: { Authorization: `Bearer ${token}` },
  });

  return response;
};

export const postRegister = async (email: string, password: string) => {
  const url = "https://api.audiencehub.ru/sign_up";

  const response = await axios.post(url, { email, password });

  return response;
};

export const postLogin = async (email: string, password: string) => {
  const url = "https://api.audiencehub.ru/sign_in";

  const response = await axios.post<UserProfile>(url, { email, password });

  return response;
};

export const putProfile = async (profile: Partial<Profile>) => {
  const url = "/v1/profiles";
  const token = localStorage.getItem("token") ?? "";

  const response = await api.put(
    url,
    { data: profile },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response;
};

export const getCategories = async () => {
  const url = "/v1/categories";

  const response = await api.get<CategoryResponse>(url);

  return response;
};

export const postOrder = async (segmentId: string) => {
  const url = "/v1/orders";
  const token = localStorage.getItem("token") ?? "";

  const response = await api.post<CreateOrder>(
    url,
    {
      data: { segment_id: segmentId },
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response;
};

export const postYandexMail = async (
  order_id: string,
  yandex_login: string
) => {
  const url = "v1/audiences";
  const token = localStorage.getItem("token") ?? "";

  try {
    const response = await api.post<any>(
      url,
      { order_id, yandex_login },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const postDemoQuery = async (
  name: string,
  email: string,
  phone?: string
) => {
  const url = "v1/requests";

  try {
    const response = await api.post<any>(url, { name, email, phone });

    console.log("res: ", response);
  } catch (e) {
    console.log("catch: ", e);
  }
};

export const postResetPassowrd = async (email: string) => {
  const url = "/v1/identity/password_reset";

  return await api.post<any>(url, { email });
};

export const putResetPassword = async (body: Record<string, string>) => {
  const url = "v1/identity/password_reset";

  return api.put<any>(url, {
    ...body,
  });
};

export const postFreeOrders = async (body: Record<string, string>) => {
  const url = "v1/free_orders"
  const token = localStorage.getItem("token") ?? "";

  return await api.post<any>(url,     {
    data: body,
  },
  { headers: { Authorization: `Bearer ${token}` } })
}

export const getVerifyEmail = async (token: string) => {
  const url = `v1/identity/email_verification?token=${token}`

  return await api.get<any>(url)
}
